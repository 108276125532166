import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../theme';
import logo from '../assets/A_modern,_symmetrical,_and_very_simple_logo_for_Co.png';

const HeaderContainer = styled.header`
  background-color: ${colors.primary};
  color: ${colors.background};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const LogoContainer = styled.div`
  width: 75px;  // Set the width of the logo container
  height: 75px;  // Set the height of the logo container
  overflow: hidden;  // Hide overflow to crop the logo
  border-radius: 50%;  // Optional: Add a border radius to make it circular
`;

const Logo = styled.img`
  width: 100%;  // Make sure the logo fills the container
  height: 100%;  // Make sure the logo fills the container
  object-fit: cover;  // Crop the logo to fill the container
  object-position: center;  // Center the logo within the container
  transform: scale(1.5);  // Scale the logo to crop it more
`;

const Nav = styled.nav`
  @media (max-width: 768px) {
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  ul li {
    margin: 0 1rem;
  }

  ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s, transform 0.3s;

    &:hover {
      color: ${colors.secondary};
      transform: scale(1.1);
    }

    &.active {
      color: ${colors.highlight};
      font-weight: bold;
    }
  }
`;

const CallToAction = styled(Link)`
  background-color: ${colors.secondary};
  color: ${colors.background};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${colors.highlight};
    transform: scale(1.1);
  }
`;

const Hamburger = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
`;

const MobileNav = styled.nav`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: ${colors.primary};
    width: 100%;
  }

  ul {
    list-style: none;
    padding: 1rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ul li {
    margin: 0.5rem 0;
  }

  ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s, transform 0.3s;

    &:hover {
      color: ${colors.secondary};
      transform: scale(1.1);
    }

    &.active {
      color: ${colors.highlight};
      font-weight: bold;
    }
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer>
      <Link to="/">
        <LogoContainer>
          <Logo src={logo} alt="Code Revolution LLC Logo" />
        </LogoContainer>
      </Link>
      <Nav>
        <ul>
          <li><NavLink to="/" exact activeClassName="active">Home</NavLink></li>
          <li><NavLink to="/about" activeClassName="active">About</NavLink></li>
          <li><NavLink to="/projects" activeClassName="active">Projects</NavLink></li>
          <li><NavLink to="/services" activeClassName="active">Services</NavLink></li>
          <li><NavLink to="/contact" activeClassName="active">Contact</NavLink></li>
        </ul>
      </Nav>
      <CallToAction to="/contact">Get in Touch</CallToAction>
      <Hamburger onClick={toggleMenu}>
        <span>&#9776;</span>
      </Hamburger>
      {isOpen && (
        <MobileNav>
          <ul>
            <li><NavLink to="/" exact activeClassName="active" onClick={toggleMenu}>Home</NavLink></li>
            <li><NavLink to="/about" activeClassName="active" onClick={toggleMenu}>About</NavLink></li>
            <li><NavLink to="/projects" activeClassName="active" onClick={toggleMenu}>Projects</NavLink></li>
            <li><NavLink to="/services" activeClassName="active" onClick={toggleMenu}>Services</NavLink></li>
            <li><NavLink to="/contact" activeClassName="active" onClick={toggleMenu}>Contact</NavLink></li>
            <li><CallToAction to="/contact" onClick={toggleMenu}>Get in Touch</CallToAction></li>
          </ul>
        </MobileNav>
      )}
    </HeaderContainer>
  );
};

export default Header;
