import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { Section, Title, Paragraph } from '../styles';

const CertificationCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 1rem 0;
  text-align: center;
`;

const CertificationTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.primary};
  margin-bottom: 0.5rem;
`;

const CertificationDescription = styled(Paragraph)`
  color: ${colors.textSecondary};
`;

const Certifications = () => (
  <Section>
    <Title>Certifications and Awards</Title>
    <CertificationCard>
      <CertificationTitle>Certified React Native Developer</CertificationTitle>
      <CertificationDescription>
        Recognized by the React Native Certification Board for demonstrating expertise in building complex mobile applications.
      </CertificationDescription>
    </CertificationCard>
    <CertificationCard>
      <CertificationTitle>Top AI Solutions Provider 2024</CertificationTitle>
      <CertificationDescription>
        Awarded by Tech Innovators for our groundbreaking work in AI and machine learning solutions.
      </CertificationDescription>
    </CertificationCard>
  </Section>
);

export default Certifications;
