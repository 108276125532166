// src/components/ParallaxSection.js
import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme'; // Ensure colors is imported
import parallaxImage from '../assets/A_modern,_abstract_parallax_background_image_for_C.png';

const Parallax = styled.div`
  background: url(${parallaxImage}) no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  color: white;
  font-size: 2rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: -1;
  }

  @media (max-width: 768px) {
    background-attachment: scroll;
    font-size: 1.5rem;
  }
`;

const ParallaxText = styled.h2`
  color: ${colors.background}; /* High contrast color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9); /* Text shadow for better readability */
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
`;

const ParallaxSection = ({ text }) => (
  <Parallax>
    <ParallaxText>{text}</ParallaxText>
  </Parallax>
);

export default ParallaxSection;
