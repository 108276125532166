import React from 'react';
import { Section, Title, Paragraph } from '../styles';

const About = () => (
  <Section>
    <Title>About Us</Title>
    <Paragraph>
      Code Revolution LLC was founded with a mission to provide innovative and scalable solutions to businesses of all sizes. Our team of experienced developers is passionate about technology and dedicated to delivering high-quality results. We believe in building strong relationships with our clients and helping them achieve their goals through our expertise in web and mobile development.
    </Paragraph>
  </Section>
);

export default About;
