import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { Section, Title, Paragraph } from '../styles';

const SkillCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 1rem 0;
  text-align: center;
`;

const SkillTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.primary};
  margin-bottom: 0.5rem;
`;

const Skills = () => (
  <Section>
    <Title>Our Skills and Technologies</Title>
    <SkillCard>
      <SkillTitle>React Native</SkillTitle>
      <Paragraph>Expertise in building cross-platform mobile applications using React Native.</Paragraph>
    </SkillCard>
    <SkillCard>
      <SkillTitle>AI and Machine Learning</SkillTitle>
      <Paragraph>Proficiency in developing AI-powered solutions and machine learning models.</Paragraph>
    </SkillCard>
    <SkillCard>
      <SkillTitle>Web Development</SkillTitle>
      <Paragraph>Experience in full-stack web development using modern frameworks and technologies.</Paragraph>
    </SkillCard>
    <SkillCard>
      <SkillTitle>Cloud Services</SkillTitle>
      <Paragraph>Knowledge in leveraging cloud platforms for scalable and secure applications.</Paragraph>
    </SkillCard>
  </Section>
);

export default Skills;
