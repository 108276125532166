import React from 'react';
import { Link } from 'react-router-dom';
import Hero, { HeroTitle, HeroSubtitle } from '../styles';
import { Section, Title, Paragraph, Button } from '../styles';
import heroImage from '../assets/A_modern,_sleek_hero_section_background_image_for_.png';

const Home = () => (
  <div>
    <Hero bgImage={heroImage}>
      <HeroTitle>Innovative Solutions for Modern Development</HeroTitle>
      <HeroSubtitle>Transforming Ideas into Reality with Cutting-Edge Technology</HeroSubtitle>
      <Button as={Link} to="/services">Our Services</Button>
    </Hero>
    <Section>
      <Title>About Code Revolution LLC</Title>
      <Paragraph>
        At Code Revolution LLC, we specialize in creating innovative and scalable solutions for web and mobile applications. With expertise in React Native, AI projects, and a range of other technologies, we help businesses transform their ideas into reality. Our commitment to excellence and passion for technology drive us to deliver high-quality results for our clients.
      </Paragraph>
    </Section>
  </div>
);

export default Home;
