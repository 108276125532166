
import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { Section, Title, ProjectCard, ProjectTitle, ProjectDescription } from '../styles';

const Projects = () => (
  <Section>
    <Title>Our Projects</Title>

    <CategoryTitle>Mobile App Development</CategoryTitle>
    <ProjectCard>
      <ProjectTitle>React Native Pilot Project</ProjectTitle>
      <ProjectDescription>
        Directed the integration of React Native into existing Android and iOS apps using Swift and Kotlin, developed custom Native Modules, and trained teams on the new stack, accelerating feature implementation and time to market.
      </ProjectDescription>
    </ProjectCard>
    <ProjectCard>
      <ProjectTitle>Delivery App Development</ProjectTitle>
      <ProjectDescription>
        Developed a comprehensive delivery application using React Native, including a custom checkout screen with Stripe API integration, ordering, delivery tracking, and payment processing. Implemented Firebase Cloud Functions for server-side logic and unique features such as swipe gestures for product selection and gamification elements to enhance user engagement.
      </ProjectDescription>
    </ProjectCard>
    <ProjectCard>
      <ProjectTitle>Store Management Application</ProjectTitle>
      <ProjectDescription>
        Created a React Native application for managing store products and promotions, including product listing, inventory management, and promotional activities.
      </ProjectDescription>
    </ProjectCard>
    <ProjectCard>
      <ProjectTitle>Mobile CRM</ProjectTitle>
      <ProjectDescription>
        Created a mobile CRM tool using Cordova and Ionic, enabled offline functionality with CouchDB and PouchDB, and implemented Oauth2 authentication, improving operational efficiency.
      </ProjectDescription>
    </ProjectCard>

    <CategoryTitle>Web Development</CategoryTitle>
    <ProjectCard>
      <ProjectTitle>Angular Technology Stack Implementation</ProjectTitle>
      <ProjectDescription>
        Led the adoption of Angular, developed a web application using Google App Engine, Python, Angular, and TypeScript, and trained developers on the new stack.
      </ProjectDescription>
    </ProjectCard>
    <ProjectCard>
      <ProjectTitle>CRM Web App</ProjectTitle>
      <ProjectDescription>
        Created a CRM web app using Google App Engine, Python, Angular, and TypeScript enabled offline functionality with CouchDB and PouchDB, and implemented Oauth2 authentication, improving operational efficiency.
      </ProjectDescription>
    </ProjectCard>
    <ProjectCard>
      <ProjectTitle>No-Code Application Generation</ProjectTitle>
      <ProjectDescription>
        Developed an algorithm with Jtwig to automatically generate single-page Angular applications from user selections, enabling users with no coding experience to create production-ready enterprise applications in hours.
      </ProjectDescription>
    </ProjectCard>
    <ProjectCard>
      <ProjectTitle>App Linking and Data Transfer Logic</ProjectTitle>
      <ProjectDescription>
        Designed and implemented logic and data structures to link and transfer data between applications on the no-code platform, allowing users to build interconnected suites of applications.
      </ProjectDescription>
    </ProjectCard>

    <CategoryTitle>Cloud Services</CategoryTitle>
    <ProjectCard>
      <ProjectTitle>Full-Stack Development and Integration</ProjectTitle>
      <ProjectDescription>
        Utilized Firebase Firestore for data storage, Firebase Authentication for secure login, and integrated backend services using Firebase Cloud Functions for efficient data handling and real-time updates.
      </ProjectDescription>
    </ProjectCard>
    <ProjectCard>
      <ProjectTitle>Cloud Infrastructure Management</ProjectTitle>
      <ProjectDescription>
        Managed cloud infrastructure setup and maintenance using AWS, Google Cloud, and Azure, ensuring scalable, secure, and cost-effective solutions for various applications.
      </ProjectDescription>
    </ProjectCard>

    <CategoryTitle>UI/UX Design</CategoryTitle>
    <ProjectCard>
      <ProjectTitle>React Native UI Library</ProjectTitle>
      <ProjectDescription>
        Managed a team to build an internal UI library with reusable components using React Native and TypeScript, ensuring a consistent, high-quality user interface.
      </ProjectDescription>
    </ProjectCard>
    <ProjectCard>
      <ProjectTitle>Dynamic Components and Settings Management</ProjectTitle>
      <ProjectDescription>
        Created dynamic web and mobile components using NativeScript, Angular, and TypeScript, and developed an application to manage settings, user permissions, and behaviors.
      </ProjectDescription>
    </ProjectCard>

    <CategoryTitle>Project Management</CategoryTitle>
    <ProjectCard>
      <ProjectTitle>Modular Workflow Implementation</ProjectTitle>
      <ProjectDescription>
        Established a modular workflow with tools and templates for React Native modules, streamlined module publishing with NPM, and conducted workshops, resulting in a scalable architecture.
      </ProjectDescription>
    </ProjectCard>
    <ProjectCard>
      <ProjectTitle>Development Environment Optimization</ProjectTitle>
      <ProjectDescription>
        Automated dependency management with Bash scripts, GitLab, and Bitrise CI/CD pipelines, documented Gradle and CocoaPods changes, facilitating seamless collaboration among 150 developers.
      </ProjectDescription>
    </ProjectCard>
  </Section>
);

const CategoryTitle = styled.h3`
  font-size: 1.8rem;
  color: ${colors.secondary};
  margin-top: 2rem;
`;

export default Projects;
