import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { Section, Title, Paragraph } from '../styles';

const ServiceCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 1rem 0;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const ServiceTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.primary};
  margin-bottom: 0.5rem;
`;

const ServiceDescription = styled(Paragraph)`
  color: ${colors.textSecondary};
`;

const Services = () => (
  <Section>
    <Title>Our Services</Title>
    <ServiceCard>
      <ServiceTitle>Web Development</ServiceTitle>
      <ServiceDescription>
        We offer full-stack web development services, including front-end and back-end development, to build responsive and scalable web applications.
      </ServiceDescription>
    </ServiceCard>
    <ServiceCard>
      <ServiceTitle>Mobile App Development</ServiceTitle>
      <ServiceDescription>
        Our team specializes in developing high-quality mobile applications for both Android and iOS platforms using React Native.
      </ServiceDescription>
    </ServiceCard>
    <ServiceCard>
      <ServiceTitle>AI Solutions</ServiceTitle>
      <ServiceDescription>
        We provide AI-powered solutions, including machine learning models and AI tools, to help businesses automate processes and gain insights from data.
      </ServiceDescription>
    </ServiceCard>
    <ServiceCard>
      <ServiceTitle>UI/UX Design</ServiceTitle>
      <ServiceDescription>
        Our design team creates user-centric UI/UX designs that ensure a seamless and engaging user experience across all devices.
      </ServiceDescription>
    </ServiceCard>
    <ServiceCard>
      <ServiceTitle>Cloud Services</ServiceTitle>
      <ServiceDescription>
        We offer cloud services to help businesses leverage the power of cloud computing for scalability, security, and cost-efficiency.
      </ServiceDescription>
    </ServiceCard>
  </Section>
);

export default Services;
