import styled from 'styled-components';
import { colors } from './theme';

export const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  padding: 2rem;
  background-color: ${colors.background};
  color: ${colors.text};
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const HeaderContainer = styled.header`
  background-color: ${colors.primary};
  color: ${colors.background};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  height: 50px;
`;

export const Nav = styled.nav`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  ul li {
    margin: 0 1rem;
  }

  ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s, transform 0.3s;

    &:hover {
      color: ${colors.secondary};
      transform: scale(1.1);
    }
  }
`;

export const Footer = styled.footer`
  background-color: ${colors.primary};
  color: ${colors.background};
  text-align: center;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 1rem;
`;

export const Section = styled.section`
  margin: 2rem 0;
  padding: 2rem;
  background: ${colors.background};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: ${colors.text};
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.primary};
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  color: ${colors.textSecondary};
`;

export const Button = styled.button`
  background-color: ${colors.secondary};
  color: ${colors.background};
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.highlight};
  }
`;

export const ProjectCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 1rem 0;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

export const ProjectTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.primary};
  margin-bottom: 0.5rem;
`;

export const ProjectDescription = styled(Paragraph)`
  color: ${colors.textSecondary};
`;

const Hero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  background: url(${props => props.bgImage}) no-repeat center center;
  background-size: cover;
  position: relative;
  text-align: center;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: -1;
  }
`;

export const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: ${colors.background}; /* High contrast color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for better readability */
`;

export const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  color: ${colors.background}; /* High contrast color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for better readability */
`;

export default Hero;
