import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { Section, Title, Paragraph } from '../styles';

const CaseStudyCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 1rem 0;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const CaseStudyTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.primary};
  margin-bottom: 0.5rem;
`;

const CaseStudyDescription = styled(Paragraph)`
  color: ${colors.textSecondary};
`;

const CaseStudies = () => (
  <Section>
    <Title>Case Studies</Title>
    <CaseStudyCard>
      <CaseStudyTitle>React Native Delivery App</CaseStudyTitle>
      <CaseStudyDescription>
        We developed a custom checkout screen with Stripe API integration, resulting in a seamless payment experience for users. The project involved overcoming challenges related to payment security and user interface design.
      </CaseStudyDescription>
    </CaseStudyCard>
    <CaseStudyCard>
      <CaseStudyTitle>USPS API Integration</CaseStudyTitle>
      <CaseStudyDescription>
        Our team created a React web application that integrated with the USPS API, providing real-time tracking and shipping solutions for e-commerce businesses. The project required handling complex data and ensuring API reliability.
      </CaseStudyDescription>
    </CaseStudyCard>
    <CaseStudyCard>
      <CaseStudyTitle>On-Demand Delivery Application</CaseStudyTitle>
      <CaseStudyDescription>
        We engineered a comprehensive on-demand delivery application using React Native, which included features like real-time tracking, user authentication, and push notifications. The app improved delivery efficiency and customer satisfaction.
      </CaseStudyDescription>
    </CaseStudyCard>
  </Section>
);

export default CaseStudies;
