import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import CaseStudies from './components/CaseStudies';
import Certifications from './components/Certifications';
import Blog from './components/Blog';
import Skills from './components/Skills';
import ParallaxSection from './components/ParallaxSection';
import { Container } from './styles';

const App = () => (
  <Router>
    <Header />
    <Container>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/casestudies" element={<CaseStudies />} />
        <Route path="/certifications" element={<Certifications />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/skills" element={<Skills />} />
      </Routes>
      <ParallaxSection text="Our Expertise. Your Success." />
      <Services />
    </Container>
    <Footer />
  </Router>
);

export default App;
