import React from 'react';
import { Section, Title, Paragraph } from '../styles';

const Contact = () => (
  <Section>
    <Title>Contact Us</Title>
    <Paragraph>Email: NicholasPatterson@coderevolution.com</Paragraph>
  </Section>
);

export default Contact;
