import React from 'react';
import styled from 'styled-components';
import { Section, Title, Paragraph } from '../styles';

const TestimonialCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 1rem 0;
  text-align: center;
`;

const Testimonial = ({ name, text }) => (
  <TestimonialCard>
    <Paragraph>"{text}"</Paragraph>
    <p>- {name}</p>
  </TestimonialCard>
);

const Testimonials = () => (
  <Section>
    <Title>Client Testimonials</Title>
    <Testimonial name="Client A" text="Code Revolution LLC delivered outstanding results on our project. Their expertise and professionalism were evident throughout the process." />
    <Testimonial name="Client B" text="Working with Code Revolution LLC was a great experience. They understood our needs and provided innovative solutions that exceeded our expectations." />
    <Testimonial name="Client C" text="I highly recommend Code Revolution LLC. Their team is knowledgeable, responsive, and committed to delivering high-quality work." />
  </Section>
);

export default Testimonials;
