import React from 'react';
import { Link } from 'react-router-dom';
import { Footer as StyledFooter, Button } from '../styles';

const Footer = () => (
  <StyledFooter>
    <p>© 2024 Code Revolution LLC</p>
    <Button as={Link} to="/contact">Get in Touch</Button>
  </StyledFooter>
);

export default Footer;
